<template>
    <v-container class="card pa-0" fluid>
        <v-card class="card-body ma-0 pa-0" elevation="0">
            <v-card-title :class="[this.$vuetify.theme.dark? '' : 'bg-white']" class="card-header transparent card-header-tab border-0">
                <div :class="[this.$vuetify.theme.dark? 'white--text' : '']" class="card-title text-capitalize pt-3">
                    {{$t('erp.lang_warecreate_order_abpackeinheit')}}
                </div>

                <div class="btn-actions-pane-right actions-icon-btn">
                    <v-btn @click="dialog = true" color="primary" icon>
                        <v-icon>add</v-icon>
                    </v-btn>
                </div>
            </v-card-title>
            <v-divider class="pa-0 ma-0"/>
            <v-card-text class="pa-0 ma-0">
                <Datatable
                        :api-endpoint="ENDPOINTS.DATATABLES.ERP.PACKAGES"
                        :datatable-headers="datatableHeaders"
                        :permissionDelete="true"
                        :permissionEdit="true"
                        @deleteEntries="deleteEntries"
                        @deleteEntry="deleteEntry"
                        @editEntry="editEntry"
                        no-excel-export
                        ref="packages"
                        show-delete-buttons
                        show-edit-buttons
                        show-select-checkbox
                ></Datatable>
            </v-card-text>
        </v-card>

        <v-dialog max-width="500" persistent v-model="dialog">
            <div class="card">
                <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header pt-3">
                    <div :class="[this.$vuetify.theme.dark? 'white--text' : '']" class="card-title">{{this.formTitle}}</div>
                </div>

                <div class="card-body" :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''">
                    <b-form-input :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" :state="(this.state)" @keypress.enter="save" autofocus
                                  v-model="editedItem.name"></b-form-input>
                </div>
                <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-footer text-right">
                    <v-spacer/>
                    <v-btn @click="dialog = false" class="btn btn-danger bg-danger text-light mx-auto">
                        {{this.$t('generic.lang_cancel')}}
                    </v-btn>
                    <v-btn :disabled="loading" :loading="loading" @click="save"
                           class="btn bg-primary text-light btn-primary mx-auto ml-2">
                        {{this.edit? this.$t('generic.lang_save') : this.$t('generic.lang_create')}}
                    </v-btn>
                </div>
            </div>
        </v-dialog>
    </v-container>
</template>

<script>

    import Datatable from "../../../datatable/Datatable";
    import {ENDPOINTS} from '../../../../config';
    import {Events} from "../../../../plugins/events";

    export default {
        name: "PackagesComponent",
        components: {
            Datatable
        },
        data: () => {
            return {
                ENDPOINTS,
                dialog: false,
                editedItem: {
                    id: 0,
                    name: ""
                },
                loading: false,
                defaultItem: {
                    id: 0,
                    name: ""
                },
                edit: false,
                state: null
            }
        },
        watch: {
            dialog(val) {
                val || val & this.close();
            },
            "editedItem.name": function (val) {
                if (val === '')
                    this.state = false
                else this.state = true
            }
        },
        methods: {
            deleteData(idsToDelete = []) {
                let self = this;
                this.$swal({
                  title: this.$t("erp.lang_deletePackageHead"),
                  text: this.$t("erp.lang_deletePackageUnitBody"),
                    cancelButtonText: this.$t('generic.lang_cancel'),
                    confirmButtonText: this.$t('generic.lang_delete'),
                    icon: "warning",
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
                            idsToDelete = [];
                        }
                        this.axios.post("delete/erp/packages/", {
                            packageIDs: idsToDelete,
                        }).then((res) => {
                            if (res.status === 200) {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('generic.lang_success'),
                                    color: "success"
                                });
                                self.$refs.packages.getDataFromApi();
                                self.$refs.packages.resetSelectedRows();
                            } else {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('generic.lang_errorOccurred'),
                                    color: "error"
                                });
                            }
                        });
                    },
                    allowOutsideClick: () => !this.$swal.isLoading,
                });
            },
            deleteEntry(entry) {

                this.deleteData([entry.id]);
            },
            deleteEntries(entries) {
                this.deleteData(entries);
            },
            save() {
                if (this.editedItem.name === '') {
                    this.state = false;
                    return;
                }

                if (this.edit) {
                    this.loading = true;
                    this.axios.post(ENDPOINTS.ERP.PACKAGES.UPDATE, this.editedItem).then((res) => {
                        if (res.data.STATUS === "SUCCESS") {
                            Events.$emit("showSnackbar", {
                                message: this.$t('generic.lang_success'),
                                color: "success"
                            });

                            this.$refs.packages.getDataFromApi();
                            this.$refs.packages.resetSelectedRows();
                        } else if (res.data.STATUS === "FAILED") {
                            Events.$emit("showSnackbar", {
                                message: res.data.msg,
                                color: "error"
                            });
                        } else {
                            Events.$emit("showSnackbar", {
                                message: this.$t('generic.lang_errorOccurred'),
                                color: "error"
                            });
                        }
                    }).catch((err) => {
                        Events.$emit("showSnackbar", {
                            message: err.message,
                            color: "error"
                        });
                    }).finally(() => {
                        this.loading = false;
                        this.dialog = false;
                    });
                } else {
                    this.loading = true;
                    this.axios.post(ENDPOINTS.ERP.PACKAGES.CREATE, this.editedItem).then((res) => {
                        if (res.data.STATUS === "SUCCESS") {
                            Events.$emit("showSnackbar", {
                                message: this.$t('generic.lang_success'),
                                color: "success"
                            });
                            this.$refs.packages.getDataFromApi();
                            this.$refs.packages.resetSelectedRows();
                        } else if (res.data.STATUS === "FAILED") {
                            Events.$emit("showSnackbar", {
                                message: res.data.msg,
                                color: "error"
                            });
                        } else {
                            Events.$emit("showSnackbar", {
                                message: this.$t('generic.lang_errorOccurred'),
                                color: "error"
                            });
                        }
                    }).catch((err) => {
                        Events.$emit("showSnackbar", {
                            message: err.message,
                            color: "error"
                        });
                    }).finally(() => {
                        this.loading = false;
                        this.editedItem = Object.assign({}, this.default);
                    });
                }
            },
            editEntry(entry) {
                this.edit = true;
                this.dialog = true;
                this.editedItem = Object.assign({}, entry);
            },
            close() {
                this.edit = false;
                this.dialog = false;
                this.editedItem = Object.assign({}, this.defaultItem)
            }
        },
        computed: {
            formTitle: function () {
                return this.edit ? this.$t('erp.lang_editPackageUnit') : this.$t('erp.lang_newPackageUnit');
            },
            datatableHeaders: function () {
                return [
                    {
                        text: 'ID',
                        align: 'left',
                        value: 'id',
                        width: 80,
                        hide: false,
                    },
                    {text: this.$t('generic.lang_name'), value: 'name',sort: "asc"},
                ]
            }
        }
    }
</script>

<style scoped>

</style>