<template>
    <div>
        <page-title :icon=icon
                    :permission-add="true"
                    :heading="$t('erp.lang_warecreate_order_abpackeinheit')"
                    :subheading="$t('erp.lang_warecreate_order_abpackeinheit')"
        ></page-title>

        <div class="app-main__inner">
            <packages-component/>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../../Layout/Components/PageTitle";
    import PackagesComponent from "../../../../components/erp/settings/packages/PackagesComponent";

    export default {
        name: "Packages",
        components: {PackagesComponent, PageTitle},
        data: () => ({
            icon: 'mdi mdi-package-variant icon-gradient bg-tempting-azure',
        })

    }
</script>

<style scoped>

</style>